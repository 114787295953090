/* .organizationDetailsMainGrid {
    max-width: 500px;
}
 */
.organizationDetailsMainGrid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.organizationDataDiv { grid-area: 1 / 1 / 2 / 2; }
.organizationDomainsDiv { grid-area: 1 / 2 / 2 / 3; }
.organizationMembershipsDiv { 
    grid-area: 1 / 3 / 3 / 4; 
    height: 100%;
}
.organizationLicensesDiv { 
    overflow-y: auto;
    grid-area: 2 / 1 / 3 / 2; 
}
.organizationsInvitesDiv { grid-area: 2 / 2 / 3 / 3; overflow-y: auto;} /* CHANGE TO INVITATIONS DIV */
    

.organizationDetailProductHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.organizationDetailProductItemDiv {
    border-bottom: 1px solid lightgray;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
}
.organizationDetailProductDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.organizationDetailProductItemName {
    font-weight: bold !important;
    font-size: medium !important;
}

.organizationDetailsNewProductExpiryDateButton {
    text-transform: none;
    font-size: small;
}

.organizationDetailsNewProductAssignButtonDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.organizationDetailsEmailDomainsAssignButtonDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.organizationMembershipsDiv{
    height: 95%;
}
.organizationMembershipsCard{
    height: 100%;
}
.organizationMembershipsCardContent{
    height: 90%;
}
.orgMembersList{
    height: 70%;
    overflow-y: auto;
}
.orgMembershipDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 10px;
    padding-bottom: 10px;
}
.orgMembershipRemoveIcon{
    color:red;
    font-size: 18px;
    cursor: pointer;
}
.orgMemberUuid{
    cursor: pointer;
    font-size: 18px;
    font-family: Nunito;
}
.userRoleChip{
    font-family: Nunito;
}
.adminRoleChip{
    --background:rgb(255, 125, 55);
    --color:#fff
}
.memberRoleChip{
    --background:rgb(0, 118, 8);
    --color:#fff
}
.removeMemberPopoverDiv{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.orgLicensesDiv{
    height: 100%;
}
.organizationLicensesCard{
    height: 100%;
}
.organizationLicensesCardHeader{
    height: 30%;
}
.organizationLicensesCardContent{
    height: 70%;
    overflow-y: auto;
}
.newLicenseDiv{
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.newLicensePropertyDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.newLicenseAmountInput{
    width: 20%;
    text-align: end;
    margin-top: 0;
    margin-bottom: 0;
}
.availableLicensesDiv{
    height: 90%;
    overflow: auto;
    border: 2px solid rgb(108, 108, 108);
    border-radius: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.availableLicenseCard{
    display: flex;
    flex-direction: column;
    box-shadow: none;
    cursor: pointer;
    justify-content: center;
    margin-bottom: 15px ;
    padding-left: 25px;
    border-bottom: 2px solid rgb(70, 70, 70);
}
.autoassignableIcon{
    position: absolute;
    width: 24px ;
    left: 0px;
}
.detailsArrow{
    position: absolute;
    right: 15px;
    font-size: 30px;
    cursor: pointer;
    display: none;
}
.availableLicenseCard:hover > .detailsArrow{
    display: block;
}
.licensesChip{
    user-select: none;
}
.selectedLicenseView {
    --color:#fff;
    --background:rgb(0, 97, 0);
}
.removeDomain{
    display: none;
    font-size: 18px;
    cursor: pointer;
    color: red;
}
.organizationDomainItem:hover > .removeDomain{
    display: block;
}

@media screen and (max-width: 480px) {
    .organizationDetailsMainGrid{
        display: block;    
    }
    .orgMembershipRemoveIcon{
        font-size: 35px;
    }
    .organizationMembershipsCardContent{
        overflow-y: auto;
    }
    .organizationMembershipsCard{
        height: -webkit-fill-available;
    }
}