



.orgLicenseDetailsContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.orgLicenseDetailsDiv{
    width: 30%;
    display: flex;
    flex-direction: column;
}
.orgLicenseFieldDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.orgLicenseDate{
    cursor: pointer;
}
.orgLicenseNumberInput{
    width: 20%;
    text-align: end;
}
@media screen and (max-width: 480px) {
.orgLicenseDetailsDiv{
    width: 100%;
}
}