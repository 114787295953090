.reasyUserCreationPasswordDiv {
    margin-top: 20px;
    background-color: lightgray;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.reasyUserCreationPasswordHint {
    margin: 0;
    padding: 5px;
    text-decoration: underline;
}

.reasyUserCreationPasswordTextAndButtonDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.reasyUserCreationPasswordText {
    flex: 1;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
    color: black;
    margin-right: 5px;
}