.userDetailProductHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.userDetailsMainGrid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap:50px;
    height: 90vh;
    padding: 50px;
}
.detailsSectionDiv{
    border-radius: 15px;
    border: 1px solid #939393;
    padding: 20px;
}
.sectionHeader{
    font-size: larger;
    font-weight: bold;
}
.sectionContent{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.sectionLicensesDiv{
    cursor:pointer;
}
.sectionSubscriptionsDiv{
    cursor:pointer;
}
.sectionLicensesIcon{
    font-size: 55px;
}
.userInfoDiv{
    grid-area: 1 / 1 / 3 / 2;
}
.userProductsDiv{
    height: 30vh;
    grid-area: 1 / 2 / 2 / 3; 
}
.userCommunicationDiv{
    grid-area: 2 / 2 / 3 / 3;
}

.userAssignedLicensesAccordionGroup{
    height: 30vh;
}
.userAssignedLicensesAccordion{
    height: 100%;
}
.userAssignedLicensesAccordionContent{
    height: 30vh;
}
.userLicensesDiv{
    overflow-y: scroll;
    height: 30vh;

}
.reasyUserDetailsAssignOrgBasedOnEmailButtonDiv {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.reasyUserDetailsAssignOrgBasedOnEmailButton {
    text-transform: none;
}

.mailGroupsDiv {
    display: grid;
    flex-direction: column;
    grid-template-columns: 45% 45%;
    justify-items: center;
    justify-content: space-around;
}

.changesPopover {
    --width: 50vw;
    --min-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px
}

.changesPopoverMainDiv {
    padding: 10px;
    width: 100%;
    height: 100%;
}

.changesPopoverTitle {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}

.changesPopoverSubTitle {
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    font-style: italic;
}

.changesPopopverAddedGroupsText {
    color: green;
}

.changesPopopverDeletedGroupsText {
    color: red;
}

.mailGroupsSubTitle {
    font-size: 18px;
    text-align: center;
    color: rgb(255, 200, 0);
}

.changesPopoverBtnDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.mailImportantGroups {
    width: 100%;
}

.mailDefaultGroups {
    width: 100%;
}

.mailGroupCard {
    width: 100%;
    height: 100px;
}

.mailGroupChecked {
    background-color: rgb(72, 0, 196);
    color: #fff;
}

@media screen and (max-width: 480px) {
    .userDetailsMainGrid{
        padding: 0;
        grid-template-columns: 1fr;
        gap: 10px;
    }
    .userInfoDiv{
        grid-area: 1 / 1 / 2 / 2;;
    }
    .userProductsDiv{
        grid-area: 2 / 1 / 3 / 2;
    }
    .userCommunicationDiv{
        grid-area: 3 / 1 / 4 / 2;; 
    }
    
}