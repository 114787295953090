.eventContent {
    --background: #F8F8FF;
}

.eventMainGrid {
    max-width: 700px;
    margin-top: 50px;
}

.eventFormDiv {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.eventTitleH2 {
    font-family: "Nunito", sans-serif;
    font-weight: bold;
    color: #3626A7;
    text-align: center;
    margin-bottom: 30px;
    font-size: xx-large;
}

.eventInputDiv {
    background-color: #3626A7;
    border-radius: 20px;
    overflow: hidden;
    padding: 10px;
    margin-bottom: 20px;
}

.eventInput {
    --color: white;
    color: white;
    font-size: x-large;
    font-weight: 500;
}

.eventButtonSubmit {
    text-transform: none;
    --background: #ff331f;
    color: white;
    --padding-top: 30px;
    --padding-bottom: 30px;
    --border-radius: 20px;
    font-size: x-large;
    margin-bottom: 150px;
}

.eventSentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.eventSentImg {
    height: 100px;
    width: 100px;
    object-fit: contain;
}