.mailerlitePopover{
    --width:90vw;
    --height:90vw;
}
.mailerlitePopoverDiv{
    padding: 30px;
    height: 100%;

}
.mailerlitePopoverContentDiv{
    height: 100%;
    overflow-y: scroll;
}
.addLicenseDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.addLicensePlanItem{
    width: 30%;
}