.licensesPopover{
    --width:90vw;
    --height:90vw;
}
.licensesHandlerDiv{
    padding: 30px;
    height: 100%;

}
.licensesHandlerButtonsDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 20%;
    top: 0px;
}
.licensesEditingButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #747474;
    border-radius: 15px;
    padding: 35px;
    text-align: center;
}
.licensesHandlerContentDiv{
    height: 70%;
    overflow-y: scroll;
}
.addLicenseDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.addLicensePlanItem{
    width: 30%;
}

@media screen and (max-width: 480px) {
    .licensesPopover{
        --height:90%
    }
    .licensesHandlerButtonsDiv{
        height: 30%;
    }
    .addLicensePlanItem{
        width: 100% !important;
    }
}