.profileMainGrid {
    max-width: 500px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}

.profileUserEmailParagraph {
    text-align: start;
    font-weight: bold;
    font-size: larger;
}

.profileSendRequestButtonDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profileLeadsMainDiv {
    margin-top: 20px;
    margin-bottom: 50px;
}

.profileLeadsFieldDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profileLeadsNumberParagraph {
    flex: 1;
    text-align: end;
}

.profileLeadsDislaimer {
    margin: 0;
    padding: 0;
    color: gray;
    font-size: small;
}

.profileLogoutButton {
    margin-top: 60px;
    margin-bottom: 60px;
}