.reasyHomeMainGrid {
    max-width: 500px;
}

.reasyHomeLicensesManagerDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;

    /* margin-bottom: 20px; */
    padding: 8px;
}

.reasyHomeLicensesManagerButton {
    text-transform: none;
}

.reasyHomeSpinnerDiv {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 20px;
}

.reasyHomeUsersCounterDiv {
    font-size: medium;
    margin-bottom: 20px;
}

.reasyHomeSearchbar {
    margin-bottom: 20px;
}

.reasyHomeUsersCardRowDiv {
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 1px solid lightgray;

    margin-bottom: 2px;

    cursor: pointer;
}

.reasyHomeUsersCardEmailColPar {
    flex: 1;
}