.reasyLicensesManagerMainGrid {
    max-width: 500px;
}

.reasyLicensesManagerOrganizationsListDiv {
    padding: 10px;
}

.reasyLicensesManagerOrganizationItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 1px solid lightgray;

    cursor: pointer;
}

.reasyLicensesManagerOrganizationItemName {
    flex: 1;
}