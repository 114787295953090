.reasyNotificationHeaderContainer{
    width: 100%;
    display: flex;
    flex-direction: column  ;
    justify-content: center;
    align-items: center;
}

.reasyNotificationForm{
    width: 100%;
}
.userPoolPopover{
    --width: 75vw;
    --height: 75vh;
}
.selectedByRole{
    --background: var(--ion-color-tertiary);
}
.selectedByOrganization{
    --background: var(--ion-color-tertiary);
}