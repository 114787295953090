.authenticationLoginMainGrid {
    max-width: 500px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.authenticationLoginGoogleButton {
    margin-top: 40vh;
    text-transform: none;
}